import * as React from "react"
import { Link } from "gatsby"
import { Player } from "@lottiefiles/react-lottie-player"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div className="mt-24">
            <Player src="https://assets6.lottiefiles.com/temporary_files/8lA0xO.json" autoplay keepLastFrame={true} style={{ height: '300px'}}></Player>
        </div>
        <h1 className="text-center">Questo contenuto non esiste più.</h1>
        <div className="mt-12 text-center">
            <Link className="btn btn-primary" to="/">Ritorna alla home</Link>
        </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
